import { useCookie } from "#app";
import { COOKIE_KEYS, LOCALSTORAGE_KEYS } from "~~/utils/constants";
import { useUserStore } from "~~/stores/use-user-store";
import { useAxios } from "~~/composables/useAxios";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const redirect = useCookie('redirect');

  redirect.value = to

  const router = useRouter();

  const { $get } = useAxios();
  const authToken = useCookie(COOKIE_KEYS.authToken);
  const userStore = useUserStore();

  if (!authToken.value) {
    return navigateTo("/login");
  }

  try {
    let localUser = userStore.user;

    if (!localUser) localUser = await $get("/api/v3/user/data");

    if (userStore?.setUser) userStore.setUser(localUser);

    return true;
  } catch (error) {
    console.error(error);
    return navigateTo("/login");
  }
});
